import {DataHandler} from './data-handler';
import {has, keys} from 'lodash';

import {ResponseError,Response} from 'superagent';

import {fromJS} from './util';

export class Model<TData> implements DataHandler<TData> {
    protected isStored: boolean = false;

    // @action
    handleData(data: TData) {
        this.isStored = true;

        fromJS(this, data);

        return this;
    }

    /**
     * shortcut to deal with straightforward api responses
     * @param {any}                 error
     * @param {Response<Array<TData>>} response
     */
    responseHandler = (error: ResponseError<TData>, response: Response<TData>) => {
        if (error) {
            if (response.status === 404) this.isStored = false;
            return;
        }

        this.handleData(response.body);
    }
}

export default Model;
