import * as React from 'react';
import { useKeycloak } from '@react-keycloak/web'
import axios from 'axios';
import { signinWithAccessToken } from '../../stores/user/actions';
import * as userActions from '../../stores/user/actions';
import * as appActions from '../../stores/app/actions';
import { useEffect, useState } from 'react';
import { Box, CLinearProgress, LinearProgress, Typography } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai'
import { commentsAtom } from '../../stores/jotai/comments';
import * as orgActions from '../../stores/org/actions';
import { interviewApi } from '../../api/discovery';
import { canvasActionRecieveFindings, canvasAtom } from '../../stores/jotai/canvas';
import { interviewAtom } from '../../stores/jotai/currentInterview';
import { baseAtom, BASEStateInterface, loadLibraryExternally, navigateTo, selectInsight } from '../../stores/jotai/base';
import { DiscoveryApi } from '../../api/discovery-api';
import OrgLoading from './org-loading';
import { categoryAtom } from '../../stores/jotai/categories';
import { DiscoverySessionState, discoverySessionAtom, persistSessionChange } from '../../stores/jotai/discovery-session';
import { startAppLoadingRetryTimer, stopAppLoadingRetryTimer } from '../../util/appLoadingRetryTimer';
import { useRouter } from 'next/router';
import { APP_RETRY_TIMER } from '../../constants';
import LogRocket from 'logrocket';
import { appLog } from '../../util';
import { v3MigrationAtom } from '../../stores/jotai/v3MigrationAtom';
import { checkForV3Migration } from '../../util/checkForV3Migration';
import V3MigrationAlert from './V3MigrationAlert';

export function getSessionStorageSessionID() {
    let sessionID = undefined;

    try {
        sessionID = sessionStorage.getItem('iwv3-sessionID');
    }
    catch(e) {}

    if (sessionID === undefined || sessionID === null || sessionID.length < 5) {
        sessionID = crypto.randomUUID();

        try {
            sessionStorage.setItem('iwv3-sessionID', sessionID);
        }
        catch(e) {}
    }

    return sessionID;
}

function safe(props, key, accessor) {
    if (props === undefined || props === null) return undefined;
    if (props[key] === undefined) return undefined;
    return props[key][accessor];
}

export default (props) => {
    const [authenticationState, setAuthenticationState] = useState(0);
    const [loadingStatus, setLoadingStatus] = useState('Getting your account information...');
    const [localLogoutFlag, setLocalLogoutFlag] = React.useState(false);
    const [locationFlags, setLocationFlags] = React.useState(true);
    const [sessionSetupHasEverCompleted, setSessionSetupHasEverCompleted] =
        React.useState(false);
    const [showMigrationAlert, setShowMigrationAlert] = React.useState(false);
    const setCategoryState = useSetAtom(categoryAtom);
    const [base, setBase] = useAtom(baseAtom);
    const [discoverySession, setDiscoverySession] = useAtom(discoverySessionAtom);
    const [v3MigrationAtomState, setV3MigrationAtomState] =
        useAtom(v3MigrationAtom);
    const { migratedToV3 } = v3MigrationAtomState;
    const displayApp = discoverySession.state === DiscoverySessionState.ready;
    const router = useRouter();

    const processLocationChange = () => {
        setLocationFlags(true);
    }

    const readyToLogout = base.readyToLogout;

    // Using Object destructuring
    const { keycloak, initialized } = useKeycloak()

    // Here you can access all of keycloak methods and variables.
    // See https://www.keycloak.org/docs/latest/securing_apps/index.html#javascript-adapter-reference
    const [lastSeenPath, setLastSeenPath] = React.useState("");

    const loadBaseStateData = () => {
        loadLibraryExternally(
            discoverySession,
            setBase,
            setCategoryState,
            keycloak.token,
            (payload) => {
                if (props.baseUpdate) {
                    props.baseUpdate(payload);
                }
                // update our status to ready when we've gotten the orgID back
                setDiscoverySession((s) => {
                    LogRocket.identify(s.user.userID, {
                        ...s.user,
                        name: s.user.username,
                        email: s.user.email,
                    });
                    return {
                        ...s,
                        state: DiscoverySessionState.ready,
                    };
                });
            },
            discoverySession.sessionID,
            discoverySession["session"]["orgID"],
            discoverySession["session"]["groupID"]
        );
    };

    const onMigrationFound = () => {
        loadBaseStateData();
    }

    useEffect(() => {
        setShowMigrationAlert(migratedToV3);
    }, [migratedToV3]);

    React.useEffect(() => {
        if (props.pageRequest !== lastSeenPath) {
            setLastSeenPath(props.pageRequest);

            navigateTo(setBase, props.pageRequest);
        }
    }, [props.pageRequest, lastSeenPath]);

    // When we get a setup request, and have an active keycloak token 
    // make sure our session is setup
    React.useEffect(() => {
        if (sessionSetupHasEverCompleted === true) {
            if (keycloak !== undefined) {
                if (keycloak.token !== undefined) {
                    const sessionID = getSessionStorageSessionID();

                    if (sessionID && sessionID.length > 5) {
                        if (discoverySession && discoverySession.sessionID !== sessionID) {
                            // very simple, completely inline cache retriever
                            const cachedSessionPack = localStorage.getItem(`sessionPack-${sessionID}`);

                            if (cachedSessionPack === null || cachedSessionPack === undefined) {
                                axios.get(`${process.env.NEXT_PUBLIC_LINX_URL}/session/v1`, {
                                    params: {
                                        sessionID: sessionID,
                                    },
                                    withCredentials: false, headers: {
                                        "Authorization": 'Bearer ' + keycloak.token
                                    }
                                }).then((r) => {
                                    const newSessionPack = {
                                        ...r.data,
                                        sessionID: sessionID,
                                        apiToken: keycloak.token,
                                        state: DiscoverySessionState.loading_org
                                    };

                                    setDiscoverySession(newSessionPack);

                                    persistSessionChange(newSessionPack)
                                });
                            }
                            else {
                                const cachedSessionPackObject = JSON.parse(cachedSessionPack);
                                let wasValid = false;

                                if (cachedSessionPackObject && cachedSessionPackObject.sessionID) {
                                    if (sessionID.length > 0) {
                                        if (sessionID === cachedSessionPackObject.sessionID) {
                                            setDiscoverySession({
                                                ...cachedSessionPackObject,
                                                state: DiscoverySessionState.loading_org
                                            });
                                            wasValid = true;
                                        }
                                    }
                                }

                                if (wasValid === false) {
                                    appLog('invalid session pack');
                                }
                            }
                        }
                    }
                }
            }
        }
    }, [sessionSetupHasEverCompleted, keycloak.token, discoverySession]);

    React.useEffect(() => {
        if (initialized) {
            if (keycloak.authenticated !== true) {
                // log the user in
                keycloak.login();
            }
            else {
                // once keycloak is authenticated, ensure we have an active IW session
                if (sessionSetupHasEverCompleted === false) {
                    setSessionSetupHasEverCompleted(true);

                    //console.log(keycloak);
                    //H.identify(keycloak.)
                }
            }
        }
        else {

        }
    }, [initialized, keycloak.authenticated, sessionSetupHasEverCompleted]);

    React.useEffect(() => {
        if (readyToLogout === false && localLogoutFlag === true) {
            if (keycloak.authenticated === true) {
                window.location.href = 'https://login.innovationwithin.services/realms/discovery/protocol/openid-connect/logout';
            }
        }
    }, [readyToLogout, localLogoutFlag]);

    React.useEffect(() => {
        if (readyToLogout === true) {
            setLocalLogoutFlag(true);
            appActions.clearLogoutFlag();
        }
    }, [readyToLogout, initialized, keycloak]);

    useEffect(() => {
        processLocationChange();
    }, []);

    useEffect(() => {
        let token = undefined;

        try {
            token = keycloak.token;
        }
        catch (e) {

        }

        if (token !== undefined) {
            if (token !== "undefined") {
                if (locationFlags) {
                    if (discoverySession.user !== undefined) {
                        setLocationFlags(false);

                        setAuthenticationState(1);
                        setLoadingStatus(`Loading team (${discoverySession['session']['groupID']})...`);

                        checkForV3Migration(
                            discoverySession,
                            keycloak.token,
                            setV3MigrationAtomState,
                            onMigrationFound,
                        );
                    }
                }
            }
        }
    }, [locationFlags, keycloak.authenticated, keycloak.token, initialized, discoverySession]);

    useEffect(() => {
        processLocationChange();
    }, [keycloak.authenticated, initialized]);

    const displayLoadingStatus = () => {
        return <Typography>Loading...</Typography>
    }

    const handleAtomMessageReciever = (e) => {
        const data = e.data;

        if (typeof (data) === "string") {
            if (data === "clear-insight-iwwm") {
                // selectInsight(setBase, undefined);
            }
        }
    }

    React.useEffect(() => {
        window.addEventListener('message', handleAtomMessageReciever);

        // cleanup this component
        return () => {
            window.removeEventListener('message', handleAtomMessageReciever);
        };
    }, []);

    const globalLoader = React.useMemo(() => {
        const loaderWrapperStyle = {
            position: "fixed",
            top: "0px",
            left: "0px",
            width: "100%",
            zIndex: 10000,
        };

        return !displayApp ? (
            <Box sx={loaderWrapperStyle}>
                <LinearProgress />
            </Box>
        ) : null;
    }, [displayApp])

    useEffect(() => {
        if (displayApp || showMigrationAlert) {
            stopAppLoadingRetryTimer();
        } else {
            startAppLoadingRetryTimer({
                interval: APP_RETRY_TIMER.INTERVAL,
                // app will retry until it gets a 503 response from the backend server
                retryCount: APP_RETRY_TIMER.RETRY_COUNT,
                onIntervalEnd: () => {
                    router.reload();
                },
                onRetryEnd: () => {
                    router.push("/maintenance-error");
                },
            });
        }
    }, [displayApp, showMigrationAlert]);

    // We can use this return to take over the entire page and display information
    return (
        <div style={{ height: "100%" }}>
            <V3MigrationAlert showMigrationAlert={showMigrationAlert} />
            {globalLoader}
            {props.children}
        </div>
    );
}
