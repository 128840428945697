import { Request, Response } from 'superagent';
import { ApiSettings, ApiBase } from './base';
import { PubSub } from '../../lib/pubsub';
import * as uuid from '../util/uuid';
//@ts-ignore
export const errorChannel = new PubSub<ResponseError<any>>();


export class DiscoveryApi extends ApiBase {
    //@ts-ignore
    settings: ApiSettings = {
        apiKey: 'iw',
        apiSecret: 'gsvlabs',
        //@ts-ignore
        apiEndpoint: process.env.NEXT_PUBLIC_DISCOVERY_URI ? process.env.NEXT_PUBLIC_DISCOVERY_URI : 'https://ft45cm83v6.execute-api.us-west-2.amazonaws.com/prod'
    };

    static accessTokenVariableName: string = 'accessToken';
    static sessionTokenVariableName: string = 'sessionToken';

    useBasicAuth = true;

    // any class that extends DiscoveryApi should come here to get the accessToken
    static accessToken: string = null;
    static sessionToken: string = null;
    static setAccessToken(token: string): void {
        // Persist our accessToken in the local storage
        localStorage.setItem(DiscoveryApi.accessTokenVariableName, token);

        if (typeof window !== 'undefined') {
            // Check to see if we have a sessionToken
            let foundSessionToken = sessionStorage.getItem(DiscoveryApi.sessionTokenVariableName);

            // If we don't generate a new one and store it
            if (foundSessionToken === undefined || foundSessionToken === null) {
                foundSessionToken = uuid.generate();

                sessionStorage.setItem(DiscoveryApi.sessionTokenVariableName, foundSessionToken);
            }

            this.accessToken = token;
            //this.accessToken = '60186c6f-0d20-11e8-bbc3-0649379c7cd0'
            this.sessionToken = foundSessionToken;
        }
    }

    // Retrieve the session token in our current context
    static getSessionToken(): string {
        return this.sessionToken;
    }

    // Retrieve the access token explicity from our local storage
    static getLocalAccessToken(): string {
        return localStorage.getItem(DiscoveryApi.accessTokenVariableName);
    }

    static beaconHref: string = 'https://s3-us-west-2.amazonaws.com/innovation-within-beacon-prod/innovationwithin_prod_beacon_config.json';

    // Public location to house the stripe key
    // TODO: This needs to live in a jenkins esc configuration
    static stripeKey: string = '';

    constructor() {
        super();

        DiscoveryApi.stripeKey = process.env.NEXT_PUBLIC_STRIPE_KEY || 'pk_live_wENZoSEciiEUe8dNxHfxJUSY';
    }

    artifactURL(url: string) {
        if (!url) return;

        if (url.indexOf('?') > -1) {
            return url = url + '&auth=' + this.authToken();
        } else {
            return url = url + '?auth=' + this.authToken();
        }
    }

    protected errorCallback<T>(error: ResponseError<T>, response: Response<T>) {
        // Store the response into the response error
        // This way the subscriber to this channel can also read the response
        // sent by the server.  This typically includes the proper error message
        error.resp = response;
        // Publish the packaged error
        errorChannel.publish(error);
    }

    protected setupRequest(request: Request) {
        if (DiscoveryApi.accessToken) {
            if (request._formData) {
                request.field('accessToken', DiscoveryApi.accessToken);
                request.field('sessionToken', DiscoveryApi.sessionToken);
            } else {
                request.query({ accessToken: DiscoveryApi.accessToken, sessionToken: DiscoveryApi.sessionToken });
            }
        }

        super.setupRequest(request);
    }
}

export default new DiscoveryApi();
