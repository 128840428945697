import {each} from 'lodash';

import {State} from './state';
import {toJS} from './util';

export abstract class Persister {
    // states: State[] = [];

    processObservable(name: string, object: any) {
        this.set(name, toJS(object));
    }

    processState(state: State) {
        this.processObservable(state.namespace, state);
    }

    abstract set(name: string, value: any): void;
    abstract get<T>(name: string): T;
}

export class LocalStoragePersister extends Persister {
    constructor() {
        super();

        if (typeof window !== 'undefined') {
            if (!sessionStorage) throw new Error('sessionStorage not supported.');
        }
    }

    set(name: string, value) {
        if (typeof window !== 'undefined') {
            sessionStorage.setItem(name, JSON.stringify(value));
        }
    }

    get<T>(name: string) {
        try {
            return JSON.parse(sessionStorage.getItem(name)) as T;
        }
        catch(e) {
            return {} as T;
        }
    }
}
