import {Model,CollectionArray, State} from '../base';
import {keyBy, get, each} from 'lodash';

import {dispatcher, dispatch} from '../../../lib/pork';
import {billingAPI, userAPI} from '../../api';
import {constructAction} from '../util';
import { orgApi } from '../../api/org';

export class BillingState extends State {
  namespace = 'BillingState';

  // Initial values
  timelines: { userLicenseSet: { [key: string]: api.LicenseTimelineResponse } } = { userLicenseSet: {} };
  prices: { [key: string]: BillingAPI.Price } = {};
  customer: Customer = new Customer();
  payments: Payments = new Payments();
  transactions: Transactions = new Transactions();
  credit: number = 0;
  currency: BillingAPI.Currency = 'USD';
  currentOrganizationProduct: BillingAPI.Product | null = null;
  allOrganizationsProducts: BillingAPI.Product[] | null = null;
  allOrganizationsTokens: BillingAPI.ProductToken[] | null = null;

  // Clear method for resetting values to initial state
  clear() {
    this.credit = 0;
    this.currency = 'USD';
    this.customer = new Customer();
    this.payments = new Payments();
    this.transactions = new Transactions();
    this.currentOrganizationProduct = null;
  }

  // Reserve token function
  reserveToken(
    orgID: string,
    productID: string,
    reservedUserId: string,
    reservedUserGroup: string,
    reservedUserEmail: string,
    amount: string,
    callback: () => void
  ) {
    const request: BillingAPI.ReserveTokenRequest = {
      orgID,
      productID,
      reservedUserId,
      reservedUserGroup,
      reservedUserEmail,
      amount,
    };

    billingAPI.reserveToken(request, callback);
  }

  // Load all timelines
  loadAllTimelines(orgID: string) {
    const request: BillingAPI.AllLicenseRequest = { orgID };
    orgApi.getAllLicense(request, (error, response) => {
      if (!error) {
        this.timelines = response.body;
      }
    });
  }

  // Load pricing
  loadPricing(callback: () => void) {
    billingAPI.getPricing((error, response) => {
      if (error) return;
      this.prices = indexBy(response.body, 'id');
      callback();
    });
  }

  // Purchase credit
  purchaseCredit(groupID: string, amount: number) {
    const request: BillingAPI.PurchaseRequest = {
      groupID,
      amount,
      currency: this.currency,
    };

    billingAPI.purchaseCredit(request, (error) => {
      if (error) return;
      this.checkCredit();
      this.payments.load();
    });
  }

  // Load product details
  loadProductDetails(orgID: string, sku: string, callback?: Function) {
    const request: BillingAPI.ProductRequest = { sku };
    billingAPI.getProduct(request, (error, response) => {
      if (error) return;
      const product: BillingAPI.Product = {
        id: response.body.id,
        sku: response.body.sku,
        name: response.body.name,
        description: response.body.description,
        price: response.body.price,
        currency: response.body.currency,
        duration: response.body.duration,
        freeTokens: '0',
      };
      this.currentOrganizationProduct = product;
      if (callback) callback(product);
    });
  }

  // Load product details (version 2)
  loadProductDetailsV2(accessToken:string, orgID: string, sku: string, groupID: string, callback?: Function) {
    const request: BillingAPI.ProductRequestV2 = { accessToken, sku, orgID, groupID };
    billingAPI.getProduct(request, (error, response) => {
      if (error) return;
      const product: BillingAPI.Product = {
        id: response.body.id,
        sku: response.body.sku,
        name: response.body.name,
        description: response.body.description,
        price: response.body.price,
        currency: response.body.currency,
        duration: response.body.duration,
        freeTokens: '0',
      };
      this.currentOrganizationProduct = product;
      if (callback) callback(product);
    });
  }

  // Request a license
  requestLicense(accessToken:string, orgID: string, email: string, phone: string) {
    const request: BillingAPI.LicenseRequestRequest = {
      orgID,
      email,
      phone,
      accessToken
    };
    billingAPI.createLicenseRequest(request);
  }

  // Load all products
  loadAllProducts(orgID: string) {
    billingAPI.getAllProducts(orgID, (error, response) => {
      if (!error) {
        this.allOrganizationsProducts = response.body;
      }
    });
  }

  // Load all product tokens
  loadAllProductTokens(orgID: string, callback: (data: any) => void) {
    billingAPI.getAllProductTokens(orgID, (error, response) => {
      if (!error) {
        this.allOrganizationsTokens = response.body;
        callback(response.body);
      }
    });
  }

  // Load credit history
  loadCreditHistory(orgID: string, callback: (data: any) => void) {
    billingAPI.getCreditHistory(orgID, (error, response) => {
      if (!error) {
        callback(response.body);
      }
    });
  }

  // Purchase a license
  purchaseLicense(accessToken:string, orgID: string, productID: string, productQuantity: string, name: string, callback: (error: any, action: any) => void) {
    const request: BillingAPI.UserInvoiceRequest = {
      productQuantity,
      orgID,
      productID,
      name,
      accessToken,
    };
    billingAPI.createUserInvoice(request, callback);
  }

  // Generate a free license
  freeLicense(orgID: string, productID: string, productQuantity: string, name: string, callback: (error: any, action: any) => void) {
    const request: BillingAPI.UserInvoiceRequest = {
      productQuantity,
      orgID,
      productID,
      name,
    };
    billingAPI.createFreeInvoice(request, callback);
  }

  // Check credit
  checkCredit() {
    const groupID = get(dispatcher.getState(), 'team.id') as string;
    if (!groupID) return;
    billingAPI.getCredit({ groupID }, (error, response) => {
      if (!error) {
        this.credit = response.body.amount;
        this.currency = response.body.currency.toUpperCase();
      }
    });
  }
}

// Customer class
class Customer extends Model<BillingAPI.CustomerResponse> implements BillingAPI.CustomerResponse {
  paymentMethod: string = '';
  last4Digits: string = '';

  // Load customer details
  load(accessToken: string,optionalCallback?: any) {
    billingAPI.getCustomer(accessToken,(error: any, response: any) => {
      if (!error) {
        this.responseHandler(error, response);
        this.paymentMethod = response.paymentMethod;
        this.last4Digits = response.last4Digits;
      }
      if (optionalCallback) optionalCallback(error, response);
    });
  }

  // Save customer details
  save(accessToken:string, creditCardToken: string, optionalCallback?: any) {
    const request = { creditCardToken,accessToken };
    const callback = (error: any, response: any) => {
      if (error) return;
      this.load();
      if (optionalCallback) optionalCallback(response);
    };

    if (!this.isStored) {
      billingAPI.createCustomer(request, callback);
    } else {
      billingAPI.updateCustomer(request, callback);
    }
  }
}

// Payment class
class Payment extends Model<BillingAPI.Payment> implements BillingAPI.Payment {
  amount: number = 0;
  date: string = '';
  currency: BillingAPI.Currency = '';
  last4Digits: string = '';
  paymentMethod: string = '';
}

// Payments class
export class Payments extends CollectionArray<Payment, BillingAPI.Payment> {
    Model = Payment;
  
    constructor() {
      super(); // Call the constructor of CollectionArray to initialize correctly
    }
  
    // Load all payments
    load() {
      billingAPI.getPayments((error: any, response: any) => {
        if (!error) {
          this.responseHandler(error, response);
        }
      });
    }
  }

// Transaction class
class Transaction extends Model<BillingAPI.Transaction> implements BillingAPI.Transaction {
  amount: number = 0;
  currency: string = '';
  userID: string = '';
  groupID: string = '';
  id: string = '';
  created: string = '';
}

// Transactions class
class Transactions extends CollectionArray<Transaction, BillingAPI.Transaction> {
  Model = Transaction;

  // Load all transactions
  load(request?: BillingAPI.TransactionsRequest) {
    billingAPI.getTransactions(request || {}, this.responseHandler);
  }
}

let billingState = new BillingState();
// if (DEVELOPMENT) window['billingState'] = billingState;
export default billingState;
