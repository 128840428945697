import React, { FC, ReactNode } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    SxProps,
} from "@mui/material";
import scssVars from '../../../scss/variables.module.scss'

interface AlertDialogProps {
    title?: string | ReactNode;
    description?: string | ReactNode;
    confirmButton?: ReactNode;
    abortButton?: ReactNode;
    onClose?: () => void;
    open: boolean;
    sx?: SxProps;
}

const AlertDialog: FC<AlertDialogProps> = (props) => {
    const {
        title,
        description,
        confirmButton,
        abortButton,
        open,
        onClose,
        sx,
    } = props;

    return (
        <Dialog
            sx={{ zIndex: scssVars.dialogZIndex, ...sx }}
            open={open}
            onClose={onClose}
        >
            {title && <DialogTitle>{title}</DialogTitle>}
            {description && <DialogContent>{description}</DialogContent>}
            {confirmButton || abortButton ? (
                <DialogActions>
                    {abortButton}
                    {confirmButton}
                </DialogActions>
            ) : null}
        </Dialog>
    );
};

export default AlertDialog;
