/// <reference path='./index.d.ts' />

import { assign } from 'lodash';

import { CallbackHandler } from '../base';
import { DiscoveryApi } from '../discovery-api';
import axios from 'axios';
import * as request from 'superagent';

function filterOrgDataLocations(url) {
    if (url.startsWith('"')) {
        url = url.replace('"', "");
    }

    if (url.startsWith("%22")) {
        url = url.replace('%22', "");
    }

    if (url.includes("https://innovation-within-artifacts-ci.s3.us-west-2.amazonaws.com/")) {
        url = url.replace("https://innovation-within-artifacts-ci.s3.us-west-2.amazonaws.com/", "");
        url = url.slice(0, url.indexOf("?"));

        return `https://artifact.innovationwithin.services/artifact/${url}?bucket=innovation-within-artifacts-ci`;
    }

    return ``;
}

// TODO: add types to all CallbackHandlers
// TODO: double check all types to match actual API documentation
class OrgApi extends DiscoveryApi {
    routePrefix = 'org';

    createOrg(data: api.OrgPost, callback: CallbackHandler<any>) {
        this.post('', data, null, callback);
    }

    getOrg(orgID: string, callback: CallbackHandler<OrgState>) {
        return this.get('', <api.OrgRequest>{ orgID }, callback);
    }

    getOrgV2(orgID: string, callback: CallbackHandler<OrgState>) {
        
        // Take the response we get from the discvoery api, and perform
        // a GET request it as a signed URL
        this.get('/v3', <api.OrgRequest>{ orgID }, (error, response) => {
            let orgDataLocation = response.text;

            orgDataLocation = orgDataLocation.replace('"', '');
            orgDataLocation = orgDataLocation.replace('"', '');

            request
                .get(orgDataLocation)
                .send()
                .end((err, res) => {
                    this.addRolesToOrgReturnV3(res, orgID, callback);
                });
        });
    }

    addRolesToOrgReturnV3(initialContent: any, orgID: string, callback: CallbackHandler<OrgState>) {
        this.get('/v3/roles', <api.OrgRequest>{ orgID }, (error, response) => {
            let orgRolesDataLocation = response.text;

            orgRolesDataLocation = orgRolesDataLocation.replace('"', '');
            orgRolesDataLocation = orgRolesDataLocation.replace('"', '');

            request
                .get(orgRolesDataLocation)
                .send()
                .end((err, res) => {
                    initialContent.body.roles = res.body;

                    callback(err, initialContent);
                });
        });
    }

    getOrgNotifications(orgID: string, callback: CallbackHandler<any>) {
        return this.get('/group/notification', { orgID, version: "2" }, callback);
    }

    getOrgUserImportData(orgID: string, callback: CallbackHandler<any>) {
        this.get('/user/import/data', { orgID }, callback);
    }

    updateOrgUserImportData(orgID: string, data: string, callback: CallbackHandler<any>) {
        this.upload('/user/import/data', { orgID, data }, null, callback);
    }

    getComments(cohortID: string, callback: CallbackHandler<api.CommentResponse>) {
        return this.get('/group/comments', { cohortID }, callback);
    }

    rebuildGroupCharts(groupID: string, callback: CallbackHandler<any>) {
        return this.post('/group/chart/rebuild', { groupID }, null, callback);
    }

    sendPresentationRefresh(groupID: string, callback: CallbackHandler<any>) {
        return this.put('/group/presentation/refresh', { groupID }, callback);
    }

    updateComment(data: api.Comment, callback: CallbackHandler<api.Comment>) {
        return this.put('/group/comment', data, callback);
    }

    markCommentSeen(commentID: string, callback: CallbackHandler<any>) {
        return this.put("/group/comment/seen", { commentID }, callback);
    }

    markCommentsSeen(commentIDs: string, callback: CallbackHandler<any>) {
        this.putUpload('/group/comments/seen', {
            commentIDs
        }, undefined, callback);
    }

    markAllCommentsSeen(userID: string, callback: CallbackHandler<any>) {
        return this.put("/group/comment/seen/user", { userID }, callback);
    }

    deleteComment(commentID: string, callback: CallbackHandler<any>) {
        return this.delete('/group/comment', { commentID }, callback);
    }

    updateOrg(data: api.OrgPut, callback: CallbackHandler<any>) {
        this.put('', data, callback);
    }

    updateOrgAsAdministrator(data: api.OrgPutAdministrator, callback: CallbackHandler<any>) {
        this.put('', data, callback);
    }

    deleteOrg(orgID: string, callback: CallbackHandler<any>) {
        this.delete('', <api.OrgRequest>{ orgID }, callback);
    }

    getAllBulks(orgID: string, callback: CallbackHandler<any>) {
        return this.get('/bulks', { orgID }, callback);
    }

    createBulks(data: api.BulksPut, callback: CallbackHandler<any>) {
        return this.upload('/bulks', data, undefined, callback);
    }

    deleteOrgUser(orgID: string, username: string, callback: CallbackHandler<api.GenericResponse>) {
        this.delete('/user', {
            orgID, username
        }, callback);
    }

    getAllLicense(request: BillingAPI.AllLicenseRequest, callback?: CallbackHandler<api.GenericResponse>) {
        return this.get("/alllicense", request, callback);
    }

    markNotificationAsRead(notificationID: string, callback: CallbackHandler<any>) {
        return this.put('/group/notification', { notificationID }, callback);
    }

    markNotificationsAsRead(notificationID: string, callback: CallbackHandler<any>) {
        return this.put('/group/notifications', { notificationID }, callback);
    }

    markAllNotificationsAsRead(orgID: string, callback: CallbackHandler<any>) {
        return this.put('/group/notification/all', { orgID, version: "2" }, callback);
    }

    // search
    searchContent(request: api.SearchContentRequest, callback: CallbackHandler<api.SearchContentResponse>) {
        this.get('/content', request, callback);
    }
    searchOrgUsers(data: api.OrgUserSearch, callback: CallbackHandler<api.OrgUserSearchResponse>) {
        this.get('/users', data, callback);
    }

    //invites
    inviteUsers(data: api.OrgInvitesPost, callback: CallbackHandler<api.GenericResponse>) {
        this.post('/invite', data, null, callback);
    }

    getInvites(data: api.OrgRequest, callback: CallbackHandler<api.OrgInvitesResponse>) {
        this.get('/invite', data, callback);
    }

    acceptInvite(data: api.OrgVerify, callback: CallbackHandler<string>) {
        this.put('/invite', data, callback);
    }

    updateInviteEmail(invitationCode: string, email: string, callback: CallbackHandler<string>) {
        return this.put('/invite/email', { invitationCode, email }, callback);
    }

    deleteInvites(data: api.OrgDeleteInvitesPost, callback: CallbackHandler<api.GenericResponse>) {
        this.delete('/invite', data, callback);
    }

    //groups
    addGroup(data: api.GroupPost, callback: CallbackHandler<any>) {
        this.post('/group', data, null, callback);
    }

    convertGroupToLean(groupID: string, callback: CallbackHandler<any>) {
        this.put('/group/converttoleancanvas', { groupID }, callback);
    }

    convertGroupToMission(groupID: string, callback: CallbackHandler<any>) {
        this.put('/group/converttomissioncanvas', { groupID }, callback);
    }

    getGroupMembers(orgID: string, groupID: string, callback: CallbackHandler<GroupMember[]>) {
        this.get('/group/members', { orgID, groupID, includeInstructors: true }, callback);
    }

    getGroup(data: api.GroupRequest, callback: CallbackHandler<any>) {
        this.get('/group', data, callback);
    }

    updateGroup(data: api.GroupPut, callback: CallbackHandler<any>) {
        this.put('/group', data, callback);
    }

    updateGroupViewPolicy(data: api.GroupViewPolicyPut, callback: CallbackHandler<any>) {
        this.put('/group/viewpolicy', data, callback);
    }

    updateGroupPeriods(data: api.GroupPeriodsPut, callback: CallbackHandler<any>) {
        this.put('/group/periods', data, callback);
    }

    deleteGroup(data: api.GroupRequest, callback: CallbackHandler<any>) {
        this.delete('/group', data, callback);
    }

    //Group Users
    inviteToGroup(data: api.GroupInvite, callback: CallbackHandler<api.GenericResponse>) {
        // we're going to have vinx make this request on our behalf. 
        // vinx will then handle fail cases, be able to wrap error messages
        // and will follow up with ory on succesfull ingestion into the DB
        // tldr: even if it looks like this code isn't changing there's a specific catch
        // for it to add results that weren't previously in the response
        
        this.post('/group/invite', data, null, callback);
    }

    resendInviteToGroup(data: api.ResendGroupInvite, callback: CallbackHandler<api.GenericResponse>) {
        this.post('/group/invite/resend', data, null, callback);
    }

    addUserToGroup(data: api.GroupUserPut, callback: CallbackHandler<any>) {
        this.put('/group/user', data, callback);
    }

    removeUserFromGroup(data: api.GroupUserDelete, callback: CallbackHandler<string>) {
        this.delete('/group/user', data, callback);
    }

    //activity feed
    getActivityFeed(data: api.ActivityFeedRequest, callback: CallbackHandler<api.ActivityFeedResponse>) {
        this.get('/group/activityfeed', data, callback);
    }
}

export let orgApi = new OrgApi();
