import React, { FC, Ref, forwardRef } from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";

export type IwButtonProps = {
    loading?: boolean;
} & ButtonProps;

type IwButtonRef = Ref<HTMLButtonElement>;

const IwButton: FC<IwButtonProps> = forwardRef((props, ref: IwButtonRef) => {
    const { loading, endIcon, ...otherProps } = props;

    return (
        <Button
            ref={ref}
            endIcon={loading ? <CircularProgress size={16} /> : endIcon}
            {...otherProps}
        />
    );
});

IwButton.defaultProps = {
    loading: false,
    endIcon: null,
};

IwButton.displayName = "IwButton";

export default IwButton;
