import {each, map, mapValues} from 'lodash';

import {Response} from 'superagent';

import {DataHandler} from './data-handler';
import Model from './model';

interface IMap<T> {
    [key: string]: T;
}

export abstract class BillingS {

}

export abstract class CollectionArray<TModel extends Model<TData>, TData> implements DataHandler<Array<TData>> {
    protected Model: new() => TModel; // TODO: can we leverage type argument TModel to do this automatically?

    // @observable public items: IObservableArray<TModel> = <IObservableArray<TModel>>new Array();

    constructor(data?: Array<TData> | Array<TModel>) {
        if (data && data.length) {
            if (data[0] instanceof Model) {
                this.items.replace(<Array<TModel>> data);
            } else {
                this.handleData(<Array<TData>> data);
            }
        }
    }

    toJS() {
        return toJSON(this.items);
    }

    // @action
    handleData(data: Array<TData>) {
        let modelData: Array<TModel> = map(data, (item) => {
            let model = new this.Model();

            // instead of passing data to constructor we let the model know this data came from the server,
            // this way its marked as isStored
            model.handleData(item);

            return model;
        });

        this.items.replace(modelData);
    }

    /**
     * shortcut to deal with straightforward api responses
     * @param {any}                 error
     * @param {Response<Array<TData>>} response
     */
    responseHandler = (error, response: Response<Array<TData>>) => {
        if (error) return;

        this.handleData(response.body);
    }

    abstract load();


}