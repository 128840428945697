/// <reference path='./index.d.ts' />

import request from 'superagent';

import {CallbackHandler} from '../base';
import {DiscoveryApi} from '../discovery-api';
import { toast } from "sonner";
class BillingAPI extends DiscoveryApi {
    routePrefix = 'billing';

    getEndpoint(route: string) {
        const apiEndpoint = process.env.NEXT_PUBLIC_DISCOVERY_URI || 'https://ft45cm83v6.execute-api.us-west-2.amazonaws.com/prod';
        return `${apiEndpoint}/${this.routePrefix}${route}`;
    }

    getCustomer(accessToken: string, callback: CallbackHandler<BillingAPI.CustomerResponse>) {
        // return this.get('/customer', undefined, callback);
      const route = '/customer';
      const endpoint = this.getEndpoint(route);
      
      return request
        .get(endpoint)
        .query({ accessToken })
        .end((err, res) => {
          if (err || !res.ok) {
            callback(err, null);
          } else {
            callback(null, res);
          }
        });
    }

    getPricing(callback?: CallbackHandler<BillingAPI.PricingResponse>) {
        return this.get('/prices', undefined, callback);
    }

    createCustomer(requestData: BillingAPI.ChangeCustomerRequest, callback?: CallbackHandler<api.GenericResponse>) {
        // return this.post('/customer', request, undefined, callback);
        const route = '/customer';
        const endpoint = this.getEndpoint(route);
      
        return request
          .post(endpoint)
          .query(requestData)
          .end((err, res) => {
            if (err || !res.ok) {
              toast.error(err?.message);
              callback(err, null);
            } else {
              toast.success("Card data successfully created");
              callback(null, res);
            }
        });
    }

    updateCustomer(requestData: BillingAPI.ChangeCustomerRequest, callback?: CallbackHandler<api.GenericResponse>) {
        // return this.put('/customer', request, callback);
        const route = '/customer';
        const endpoint = this.getEndpoint(route);
      
        return request
          .put(endpoint)
          .query(requestData)
          .end((err, res) => {
            if (err || !res.ok) {
              toast.error(err?.message);
              // console.log(err, null);
            } else {
              toast.success("Card data successfully updated");
              // console.log(null, res);
            }
        });
    }

    getCredit(request: BillingAPI.CreditRequest, callback?: CallbackHandler<BillingAPI.CreditResponse>) {
        return this.get('/credit', request, callback);
    }

    purchaseCredit(request: BillingAPI.PurchaseRequest, callback?: CallbackHandler<api.GenericResponse>) {
        return this.post<api.GenericResponse>('/credit', request, undefined, callback);
    }

    giveFreeCredit(request: BillingAPI.FreeCreditRequest, callback?: CallbackHandler<api.GenericResponse>) {
        return this.post<api.GenericResponse>('/credit/free', request, undefined, callback);
    }

    createFreeInvoice(request: BillingAPI.UserInvoiceRequest, callback: CallbackHandler<api.GenericResponse>) {
        return this.post("/invoice", request, undefined, callback);
    }
    
    createUserInvoice(requestData: BillingAPI.UserInvoiceRequest, callback: CallbackHandler<api.GenericResponse>) {
      // return this.post("/invoice/user", request, undefined, callback);
      const route='/invoice/user';
      const endpoint = this.getEndpoint(route);
      
      return request
        .post(endpoint)
        .query(requestData)
        .end((err, res) => {
           if (err || !res.ok) {
              toast.error(err?.message);
              // callback(err, null);
           } else {
              toast.success("Billing successfully completed");
              // callback(null, res);
           }
      });
    }

    createLicenseRequest(requestData: BillingAPI.LicenseRequestRequest, callback?: CallbackHandler<api.GenericResponse>) {
        // return this.post('/invoice/request', request, undefined, callback);
        const route='/invoice/request';
        const endpoint = this.getEndpoint(route);
        
        return request
          .post(endpoint)
          .query(requestData)
          .end((err, res) => {
             if (err || !res.ok) {
              toast.error(err?.message);
              // console.error(err, null);
             } else {
              toast.success("License creation done");
                // console.log(null, res);
             }
        });
    }

    getFreeTokens(request: BillingAPI.FreeTokensRequest, callback: CallbackHandler<api.GenericResponse>) {
        return this.get("/invoice/freetokens", request, callback);
    }

    reserveToken(request: BillingAPI.ReserveTokenRequest, callback: CallbackHandler<api.GenericResponse>) {
        return this.put("/invoice/reservetoken", request, callback);
    }

    getProduct(requestData: BillingAPI.ProductRequest, callback: CallbackHandler<BillingAPI.Product>) {
        // return this.get("/product", request, callback);
        const route='/product';
        const endpoint = this.getEndpoint(route);
        
        return request
          .get(endpoint)
          .query(requestData)
          .end((err, res) => {
            if (err || !res.ok) {
               callback(err, null);
            } else {
               callback(null, res);
            }
        });
    }

    getAllProducts(orgID: string, callback: CallbackHandler<BillingAPI.Product[]>) {
        return this.get("/products", { orgID }, callback);
    }

    getAllProductTokens(orgID: string, callback: CallbackHandler<BillingAPI.ProductToken[]>) {
        return this.get("/invoice/alltokens", { orgID }, callback);
    }

    getTransactions(request: BillingAPI.TransactionsRequest, callback?: CallbackHandler<BillingAPI.TransactionsResponse>) {
        return this.get('/credit/transactions', request, callback);
    }

    getPayments(callback?: CallbackHandler<BillingAPI.PaymentsResponse>) {
        return this.get('/payments', undefined, callback);
    }
}

export let billingAPI = new BillingAPI();
if (process.env.NEXT_PUBLIC_DEVELOPMENT) ((typeof window !== "undefined") ? window : {})['billing'] = billingAPI;
