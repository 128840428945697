import {keys, has,merge as lodashMerge  } from 'lodash';

import {DataHandler} from './data-handler';

export function fromJS<T>(from: Object, to: T) {
    let props = keys(from);

    for (var i = props.length - 1; i >= 0; i--) {
        var key = props[i];

        if (has(from, key)) {
            if (to[key] instanceof Object && to[key]['handleData']) {
                (to[key] as DataHandler<any>).handleData(from[key]);
                if (has(from[key], 'isStored')) to[key].isStored = from[key]['isStored'];
            } else if (to[key]) {
                lodashMerge(to[key], from[key]);
                // (to[key]<any>).merge(from[key]);
            } else {
                to[key] = from[key];
            }
        }
    }
}

export function toJS<T>(from: T) {
    let props = keys(from);

    let to = {};

    for (var i = props.length - 1; i >= 0; i--) {
        var key = props[i];

        if (has(from, key)) {
            if (from[key] instanceof Object && from[key]['toJS']) {
                to[key] = from[key].toJS(from[key]);
            } else {
                to[key] = from[key];
            }
        }
    }

    return to;
}
