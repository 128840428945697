import {Persister} from '.';
import {fromJS} from './util';

export abstract class State {
    abstract namespace: string; // TODO: typescript 2.0, this should be abstract

    persist(persister: Persister) {
        fromJS(persister.get(this.namespace), this);

        autorun(() => {
            persister.processState(this);
        });
    }
}
