import React, { FC, useEffect, useState } from "react";
import AlertDialog from "./alert-dialog";
import { CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import IwButton from "./IwButton";
import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import {
    setCanCancelMigrationAlert,
    setV3MigrationStatus,
    v3MigrationAtom,
} from "../../stores/jotai/v3MigrationAtom";
import { useAtom } from "jotai";
import axios from "axios";
import { noop } from "lodash";
import {
    discoverySessionAtom,
    DiscoverySessionState,
} from "../../stores/jotai/discovery-session";
import { useKeycloak } from "@react-keycloak/web";
import { SPLASH_SCREEN_WRAPPER_STYLES } from "./IwSplashScreen";

interface ITeamInfo {
    groupID: string;
    teamName: string;
    orgID: string;
    orgName: string;
}

interface IV3MigrationAlertProps {
    showMigrationAlert: boolean;
}

const V3MigrationAlert: FC<IV3MigrationAlertProps> = (props) => {
    const { showMigrationAlert } = props;
    const [teamInfo, setTeamInfo] = useState<ITeamInfo | null>(null);
    const [fetchingTeamInfo, setFetchingTeamInfo] = useState<boolean>(false);
    const [migratingToV3, setMigratingToV3] = useState<boolean>(false);
    const { keycloak } = useKeycloak();
    const [discoverySession, setDiscoverySession] =
        useAtom(discoverySessionAtom);
    const [v3MigrationAtomState, setV3MigrationAtomState] =
        useAtom(v3MigrationAtom);
    const { canCancelMigrationAlert, onSuccessfulMigration } =
        v3MigrationAtomState;
    const appTheme = useTheme();

    const fetchTeamInfo = () => {
        setFetchingTeamInfo(true);

        if (discoverySession?.session?.groupID) {
            axios
                .get(
                    `${process.env.NEXT_PUBLIC_LINX_URL}/teams/${discoverySession?.session?.groupID}`,
                    {
                        withCredentials: false,
                        headers: {
                            Authorization: "Bearer " + keycloak.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        setTeamInfo(response?.data);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setFetchingTeamInfo(false);
                });
        }
    };

    const migrateToV3 = (onSuccess = noop, onFailure = noop) => {
        setMigratingToV3(true);

        if (discoverySession?.session?.groupID) {
            axios
                .post(
                    `${process.env.NEXT_PUBLIC_LINX_URL}/migration/${discoverySession?.session?.groupID}/v3`,
                    {
                        currentVersion: "v2",
                    },
                    {
                        withCredentials: false,
                        headers: {
                            Authorization: "Bearer " + keycloak.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 201) {
                        onSuccessfulMigration();
                        setV3MigrationStatus(setV3MigrationAtomState, false);
                        onSuccess();
                    }
                })
                .catch((error) => {
                    onFailure(error);
                })
                .finally(() => {
                    setMigratingToV3(false);
                });
        }
    };

    const onProceedToV3Migration = () => {
        migrateToV3();
    };

    const onCancelV3Migration = () => {
        setV3MigrationStatus(setV3MigrationAtomState, false);
        setCanCancelMigrationAlert(setV3MigrationAtomState, false);
        setDiscoverySession((prevSession) => ({
            ...prevSession,
            state: DiscoverySessionState.ready,
        }));
    };

    useEffect(() => {
        fetchTeamInfo();
    }, [discoverySession?.session?.groupID, keycloak.token]);

    return (
        <AlertDialog
            open={showMigrationAlert}
            title={
                <Stack
                    direction="row"
                    spacing={0.75}
                    sx={{ alignItems: "center" }}
                >
                    <ReportGmailerrorredRoundedIcon
                        color="error"
                        sx={{ fontSize: "32px" }}
                    />
                    <Typography sx={{ fontWeight: 600, fontSize: "1.25rem" }}>
                        Want to Migrate?
                    </Typography>
                </Stack>
            }
            description={
                fetchingTeamInfo ? (
                    <Stack
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            width: "540px",
                            height: "140px",
                        }}
                    >
                        <CircularProgress size={40} />
                    </Stack>
                ) : (
                    <>
                        Hey there, <strong>"{teamInfo?.teamName}"</strong> team
                        from <strong>"{teamInfo?.orgName}"</strong> organization
                        is new in this version (v3). Once you browse this team
                        in this version, everything will be converted to new
                        version and won't be accessible from v2. Do you want to
                        migrate?
                    </>
                )
            }
            confirmButton={
                !fetchingTeamInfo && (
                    <IwButton
                        variant="contained"
                        color="error"
                        loading={migratingToV3}
                        disabled={migratingToV3}
                        onClick={onProceedToV3Migration}
                        sx={{
                            svg: {
                                color: appTheme.palette.error.main,
                            },
                        }}
                    >
                        Yes, Migrate
                    </IwButton>
                )
            }
            abortButton={
                canCancelMigrationAlert ? (
                    <IwButton
                        variant="outlined"
                        color="error"
                        onClick={onCancelV3Migration}
                    >
                        Cancel
                    </IwButton>
                ) : null
            }
            sx={{
                // @ts-ignore
                zIndex: SPLASH_SCREEN_WRAPPER_STYLES.zIndex + 1,
            }}
        />
    );
};

export default V3MigrationAlert;
